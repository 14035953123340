/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import {
  IconBrandLinkedin, IconMail,
} from '@tabler/icons';
import mediumIcon from '../../../../assets/mediumIcon.svg';

const ExtLink = styled.a(({ theme: { colors } }) => ({
  color: colors.gray[400],
}));

/**
 * Linkedin,
 * Github
 * Mail
 * @returns
 */
const ProfileURLs = () => (
  <>
    <ExtLink
      href="https://www.linkedin.com/in/tejaswini-vantigodi-b446411b5/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconBrandLinkedin />
    </ExtLink>
    {/* <ExtLink href="https://github.com/tejaswinivantigodi" target="_blank" rel="noopener noreferrer">
      <IconBrandGithub />
    </ExtLink> */}
    <ExtLink
      href="mailto:tejaswinivantigodi@gmail.com"
      target="_blank"
      rel="noopener noreferrer"
    >
      <IconMail />
    </ExtLink>
    <ExtLink
      href="https://vantigoditejaswini.medium.com/"
      target="_blank"
      rel="noopener noreferrer"
      style={{
        marginLeft: '6px',
      }}
    >
      <img
        style={{
          width: '40px',
          height: '40px',
        }}
        src={mediumIcon}
        alt=""
      />
    </ExtLink>
  </>
);

export default ProfileURLs;
