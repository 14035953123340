/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Heading } from '@chakra-ui/react';
import { useMemo } from 'react';
import { HEADER_URLS } from '../../../../constants';

const SideLink = styled(NavLink)(({ theme }) => {
  const { colors } = theme;
  return {
    color: colors.gray[400],
    textDecoration: 'underline',
    textDecorationThickness: '0.13rem',
    cursor: 'pointer',
    textDecorationColor: colors.transparent,
    '&:hover': {
      color: colors.white,
      textDecorationColor: colors.white,
    },
    '&.active': {
      color: colors.white,
    },
  };
});
const SideResumeLink = styled.a(({ theme }) => {
  const { colors } = theme;
  return {
    color: colors.gray[400],
    textDecoration: 'underline',
    cursor: 'pointer',
    textDecorationThickness: '0.13rem',
    textDecorationColor: colors.transparent,
    '&:hover': {
      color: colors.white,
      textDecorationColor: colors.white,
    },
  };
});

const SideMenu = ({ onClose, size, showResume }) => {
  const urls = useMemo(() => Object.entries(HEADER_URLS), []);
  return (
    <>
      {urls.map(([name, path]) => (
        <SideLink
          exact
          to={path}
          key={path}
          onClick={onClose}
        >
          <Heading size={size} fontWeight="semibold">
            {name}
          </Heading>
        </SideLink>
      ))}
      {showResume
      && (
      <SideResumeLink href="https://drive.google.com/file/d/1jbjWWLKHBKPI2HrgV3otpSj1BJpHaAuY/view?usp=sharing" target="_blank">
        <Heading size={size} fontWeight="semibold">
          Resume
        </Heading>
      </SideResumeLink>
      )}
    </>
  );
};

SideMenu.propTypes = {
  onClose: PropTypes.func,
  size: PropTypes.string,
  showResume: PropTypes.bool,
};

SideMenu.defaultProps = {
  onClose: () => {},
  size: 'xs',
  showResume: 'false',
};
export default SideMenu;
