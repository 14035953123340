import { extendTheme } from '@chakra-ui/react';
import config from './config';

const theme = extendTheme({
  config,
  fonts: {
    heading: "'Outfit', sans-serif",
    body: "'Outfit', sans-serif",
  },
  fontWeights: {
    normal: 200,
    medium: 200,
    semibold: 300,
    bold: 400,
    black: 500,
  },
  styles: {
    global: {
      body: {
        bg: '#18191A',
      },
    },
  },
});

export default theme;
