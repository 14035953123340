/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { useTimeout } from 'react-use';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

const Container = styled.div({
  display: 'flex',
  height: '100vh',
  width: '100vw',
  zIndex: 20,
  position: 'absolute',
  top: 0,
  left: 0,
  justifyContent: 'center',
  alignItems: 'center',
});

/** Usage : <Loading fallback={url} timeout={ms}  /> */
const Loading = ({ fallback, timeout }) => {
  const [ready] = useTimeout(timeout);

  return fallback && ready() ? (
    <Redirect to={fallback} />
  ) : (
    <Container />
  );
};

Loading.propTypes = {
  fallback: PropTypes.string,
  timeout: PropTypes.number,
};

Loading.defaultProps = {
  fallback: null,
  timeout: 0,
};

export default Loading;
