/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLockBodyScroll } from 'react-use';
import { Stack, HStack } from '@chakra-ui/react';
import ProfileURLs from '../ProfileURLs/ProfileURLs';
import SideMenu from '../SideMenu/SideMenu';
import { ReactComponent as Logo } from '../../../../assets/svg_js_logo.svg';

const MobileMenuContainer = styled.nav(({ theme, isScrolled }) => {
  const { breakpoints, colors } = theme;
  return {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100vw',
    height: '100vh',
    padding: '0 2rem',
    backdropFilter: 'blur(12px)',
    background: `${colors.gray[900]}A0`,
    display: 'flex',
    [`@media screen and (min-width: ${breakpoints.md})`]: {
      display: 'none',
    },
    flexDirection: 'column',
    justifyContent: 'space-between',
    transform: `translateY(${isScrolled ? '0px' : '-1rem'})`,
  };
});

const MobileHeader = styled.div(({ isScrolled }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  height: isScrolled ? '4.5rem' : '7rem',
  alignItems: 'center',
}));

const MobileMenu = ({ onClose, isScrolled }) => {
  useLockBodyScroll();
  return (
    <MobileMenuContainer isScrolled={isScrolled}>
      <MobileHeader>
        <Link to="/" onClick={onClose}>
          <Logo
            css={{
              height: 18,
            }}
          />
        </Link>
      </MobileHeader>
      <Stack
        spacing="16px"
      >
        <SideMenu onClose={onClose} size="lg" />
      </Stack>
      <HStack spacing="16px" mb="6.5rem">
        <ProfileURLs />
      </HStack>
    </MobileMenuContainer>
  );
};

MobileMenu.propTypes = {
  onClose: PropTypes.func.isRequired,
  isScrolled: PropTypes.bool.isRequired,
};

export default MobileMenu;
