/** @jsxImportSource @emotion/react */
import { lazy, Suspense } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import TejaUI from '../TejaUI/TejaUI';
import Loading from './Loading/Loading';

const Works = lazy(() => import('../Works/Works'));
const Work = lazy(() => import('../Work/Work'));
const BrainStorms = lazy(() => import('../BrainStorms/BrainStorms'));
const Contact = lazy(() => import('../Contact/Contact'));
const Home = lazy(() => import('../Home/Home'));
const Arts = lazy(() => import('../Arts/Arts'));
const About = lazy(() => import('../About/About'));
const Router = () => (
  <BrowserRouter>
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route path="/why-ui" component={TejaUI} />
        <Route exact path="/brainstorms/:slug" component={BrainStorms} />
        <Route exact path="/works/:slug" component={Work} />
        <Route exact path="/works" component={Works} />
        <Route path="/arts" component={Arts} />
        <Route path="/about" component={About} />
        <Route path="/contact" component={Contact} />
        <Route exact path="/" component={Home} />
      </Switch>
    </Suspense>
  </BrowserRouter>
);

export default Router;
