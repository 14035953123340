import FlameOn1 from '../assets/flameon/1.jpg';
import FlameOn2 from '../assets/flameon/2.jpg';
import FlameOn3 from '../assets/flameon/3.jpg';
import FlameOn4 from '../assets/flameon/4.jpg';
import FlameOn5 from '../assets/flameon/5.jpg';
import FlameOn6 from '../assets/flameon/6.jpg';
import FlameOn7 from '../assets/flameon/7.jpg';
import FlameOn8 from '../assets/flameon/8.jpg';

import UpperEast1 from '../assets/uppereast/1.png';
import UpperEast2 from '../assets/uppereast/2.png';
import UpperEast3 from '../assets/uppereast/3.png';
import UpperEast4 from '../assets/uppereast/4.png';
import UpperEast5 from '../assets/uppereast/5.png';
import UpperEast6 from '../assets/uppereast/6.png';
import UpperEast7 from '../assets/uppereast/7.png';
import UpperEast8 from '../assets/uppereast/8.png';
import UpperEast9 from '../assets/uppereast/9.png';
import UpperEast10 from '../assets/uppereast/10.png';

import InstagramRedesign1 from '../assets/instagramRedesign/InstagramRedesign1.jpeg';
import InstagramRedesign2 from '../assets/instagramRedesign/InstagramRedesign2.jpeg';
import InstaRedesignIntroImg from '../assets/instagramRedesign/InstaRedesignIntro.png';
import InstagramRedesignThumbnail from '../assets/instagramRedesign/InstagramRedesignThumbnail.png';

const HEADER_URLS = {
  Works: '/works',
  Arts: '/arts',
  Contact: '/contact',
  About: '/about',
};

const WORKS_DATA = [
  {
    slug: 'Flame-On',
    name: 'FLAME ON',
    short: 'Web Development',
    description: `This project is for ensuring a smooth experience for the customers to order
      food through a website.
      The simplicity and ease of access of a menu are the main things that
      facilitate ordering food in a restaurant.
      A Tablet menu completely revolutionizes the patron’s dining experience.`,
    about: `This Responsive 'FlameOn' project is developed using HTML,CSS and ReactJS. 
    And Firebase is used for maintaining databases. 
    The project has a lot of features . Users can add items to cart. 
    Users can change the quantity i.e., Users can add , delete and modify items in cart. 
    It contains a homepage from where users can view the menu,browse through it. 
    After adding items to cart,users can enter details and checkout. 
    All the data will be stored in Firebase.`,
    languages: ['HTML', 'CSS', 'ReactJS'],
    images: [
      { url: FlameOn1, alt: 'home page' },
      { url: FlameOn2, alt: 'contact page' },
      { url: FlameOn3, alt: 'menu' },
      { url: FlameOn4, alt: 'menu' },
      { url: FlameOn5, alt: 'add to cart' },
      { url: FlameOn6, alt: 'add to cart' },
      { url: FlameOn7, alt: 'customer credentials ' },
      { url: FlameOn8, alt: 'payment portal' },
    ],
  },
  {
    slug: 'Upper-East-Side',
    name: 'THE UPPEREAST SIDE',
    short: 'Web Development',
    description:
      "This project is an online Restaurant Website where people can scroll through to avail different features like as in viewing the menu, making a reservation, writing review and many more. This is a restaurant website 'The UpperEast side' project is developed using HTML, CSS, Js. It contains a homepage from where users can view the menu, browse through it and can write to us through mail and can follow us on social media too.",
    about:
      "Online Restaurant Management System 'The UpperEast Side' is a web application. Restaurant website serves the main purpose of providing people with easy access to their favorite restaurant and its food. This is a front-end page of a restaurant which is designed in a way to have an easy access to things needed. People can view the menu , make a reservation , write a review, and follow the restaurant on social media.",
    languages: ['HTML', 'CSS', 'JS'],
    images: [
      { url: UpperEast1, alt: 'home' },
      { url: UpperEast2, alt: 'about' },
      { url: UpperEast3, alt: 'about' },
      { url: UpperEast4, alt: 'menu' },
      { url: UpperEast5, alt: 'menu' },
      { url: UpperEast6, alt: 'reservation' },
      { url: UpperEast7, alt: 'contact' },
      { url: UpperEast8, alt: 'menu' },
      { url: UpperEast9, alt: 'menu' },
      { url: UpperEast10, alt: 'menu' },
    ],
  },
];

const BRAIN_STORM_DATA = [
  // {
  //   slug: 'Taste-Buds',
  //   name: 'Taste Buds',
  //   short: 'UI',
  //   contents: {
  //     'What?':
  //       'Taste buds is a food ordering app which allows people to order food.',
  //     'Who?':
  //       'It is targeted to a large number of audience. “You hungry? Order it!”.',
  //     'Why?': 'Easy way to fuel your stomach.',
  //     'Where?': 'Smartphones',
  //   },
  //   images: [
  //     { url: TasteBuds1, alt: 'moodboard' },
  //     { url: TasteBuds2, alt: 'buttons' },
  //     { url: TasteBuds3, alt: 'state' },
  //     { url: TasteBuds4, alt: 'prototype' },
  //     { url: TasteBuds5, alt: 'prototype' },
  //     { url: TasteBuds6, alt: 'prototype' },
  //   ],
  // },
  {
    slug: 'instagram-redesign',
    name: 'Instagram Redesign',
    short: 'UI | Case Study',
    introimg: InstaRedesignIntroImg,
    thumbnailimg: InstagramRedesignThumbnail,
    contents: {
      'PROJECT VISION':
        'Instagram is a world wide used social media application. The user population is young and old and frequent changes in the design and placement of menus is over whelming and not appealing to the younger generation. This receives frustration from the users ',
      'WHAT I DID:': ['UX/UI Design', 'UX Research', 'Interaction Design'],
      'TIIMELINE:': '2 weeks',
      'PROBLEM STATEMENT:': [
        'The navigation bar needs restructuring.',
        'The features such add post, likes is in the no thumb area on the home screen.',
        'The Reels cannot be saved in specific folder',
      ],
      'EMPATHIZE:':
        'For this study the population I chose was youth between the age of 15-22 and also senior group of people between age 45-50. I wanted to understand the frustrations while using the app and what features they expect to come to when they visit the application for use. I talked to thirteen users for better understanding and shared an online questionnaire to record their thoughts about the application.',
      'DEFINE:': [
        'Survey Questionnaire and analysis.',
        'How often do you use instagram?',
        'What do you first notice when you open the application?',
        'What do you feel about the navigation bar at the bottom?',
        'How do you feel about the placement of like and add post menu tab?',
        'Do you like to save reels in a specific folder?',
        'Do you use the shopping tab in the navigation bar?',
      ],
      'RESEARCH GOALS': [
        'PROBLEMS:',
        'Extra features that could be used.',
        'Experience',
      ],
      'AGE GROUP': '13-50 Because they use it the mostIt is made for them',
      'EXTENDED INTERVIEW QUESTIONS': [
        'What are the problems you face while using?',
        'What is the feature that you use the most?',
        'What is the feature that you like the most?',
        'What is the feature that you dislike the most?',
        'How is the experience throughout the app?',
        'What if I move this feature over here?',
        'What frustrates you the most?',
      ],
      'IDEATE:':
        'After talking to interviewees I gained better insights on the needs and frustrations, I designed a few solutions to address the problems and pain points.',
      'SOLUTIONS:': [
        'Rearranging the menu tabs in the bottom bar allowing easy navigation through various pages.',
        'Bringing the like and post menu tabs to the Navigation bar.',
        'Addition of save in folder menu for the reels.',
        'Eliminating the shopping tab from the navigation bar.',
      ],
      // 'Current Layout of application': {
      //   url: InstagramRedesign1,
      //   alt: 'wireframes',
      // },
      // Redesign: { url: InstagramRedesign2, alt: 'wireframes' },
      'TAKEAWAYS:':
        'Working on this project helped me think about the design process in more details. It helped me gain insights on the user research too while conducting the surveys and the interviews of the users. The redesign of features makes the application less frustrating improving user experience.',
    },
    images: [
      {
        url: InstagramRedesign1,
      },
      { url: InstagramRedesign2 },
    ],
  },
  // {
  //   slug: 'Thrift-Spirt',
  //   name: 'ThriftSpirit',
  //   short: 'UI',
  //   contents: {
  //     'What ?':
  //       'Earth is the only planet for humans to live.
  //        Due to the fast growing Fashion Industry,
  //        every year loads and bundles of clothes are dumped off the street
  //        causing accumulation of them.
  //        ThriftSpirit aims to Sell second hand clothes
  //         which are good to use. The user can shop thrifted clothes,
  //        can donate clothes by uploading pictures of clothes,
  //         can donate money to charity and
  //         has an option for customers to opt for social service.',
  //     Functions: [
  //       'ThriftSpirit sells clothes which are thrifted and good to use.',
  //       'The customer can donate their clothes by uploading the pictures.',
  //       'Can donate money to charity.',
  //       'Social service.',
  //     ],
  //     'Potential Roles': [
  //       'People who want to reduce fashion waste and contribute to a greener environment.',
  //       'Who want to donate clothes to charity.',
  //       'Who want to donate money to charity.',
  //       'Social service.',
  //       "Earth being the only planet for humans to live,
  // it is our responsibility to take care of it. Every year on an average,
  // about 60% of a person's clothes are thrown away. On an estimate,
  // 18.3 million tons of clothes end up in the landfill. To counter
  // this wastage and problem ThriftSpirit offers a helping hand to
  // reduce the waste generated by selling thrifted clothes.
  // This provides a platform to people to buy thrifted clothes which
  // will be of much help to the environment. This application encourages
  // people to take up thrifting and use thrifted clothes as
  // it has a huge effect on the reducing the wastage.",
  //     ],
  //     'Designer Goals': [
  //       'Reduce Fashion Waste (The customer will have a wide
  //        range of options to select from. They can choose and shop.)',
  //       'Providing a platform for customers to donate
  // clothes.(Apart from shopping the customer can also
  //   donate the clothes by uploading the pictures of the
  //   clothes that they want to donate).',
  //       'Run a Charitable Trust(People can also donate
  // money to charity which will be forwarded to a charitable
  // trust and the money is put to good use.)',
  //       'Building a community, reaching out to people
  // to invest their time in Social service(People can volunteer
  //   for Social Service too.',
  //     ],
  //   },
  //   images: [
  //     { url: ThriftSpirit1, alt: 'moodbard' },
  //     { url: ThriftSpirit2, alt: 'site map' },
  //   ],
  // },
  // {
  //   slug: 'Illusion',
  //   name: 'Illusion',
  //   short: 'UI',
  //   contents: {
  //     'What?': 'An art museum portal.',
  //     'Who?': 'Art enthusiasts.',
  //     'Where?': 'Smartphones',
  //     Functions: [
  //       'Scroll through the art pieces displayed for sale',
  //       'Sell art online by uploading the details of the piece.',
  //       'Promote art galleries, putting a word out.',
  //       'Advertise art stores.',
  //       'Details of upcoming Exhibitions',
  //       'Grand openings of high tech galleries.',
  //     ],
  //   },
  //   images: [
  //     { url: Illusion1, alt: 'moodboard' },
  //     { url: Illusion2, alt: 'wireframes' },
  //     { url: Illusion3, alt: 'wireframes' },
  //     { url: Illusion4, alt: 'wireframes' },
  //     { url: Illusion5, alt: 'wireframes' },
  //     { url: Illusion6, alt: 'prototype' },
  //     { url: Illusion7, alt: 'prototype' },
  //     { url: Illusion8, alt: 'prototype' },
  //   ],
  // },
];
export { HEADER_URLS, WORKS_DATA, BRAIN_STORM_DATA };
