/** @jsxImportSouce @emotion/react */
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme';
import Router from './router';
import 'aos/dist/aos.css';

function App() {
  return (
    <ChakraProvider theme={theme}><Router /></ChakraProvider>
  );
}

export default App;
