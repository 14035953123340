import styled from '@emotion/styled';

const Container = styled.div(({ theme, type }) => {
  const { breakpoints } = theme;
  return {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    /**
     * If the type is center stick inside a smaller constraint, otherwise, branch out.
     */
    ...(type === 'center' ? {
      [`@media screen and (min-width: ${breakpoints.sm})`]: {
        maxWidth: 1024,
        margin: '0 auto',
      },
      [`@media screen and (min-width: ${breakpoints['2xl']})`]: {
        maxWidth: 1280,
        margin: '0 auto',
      },
    } : {
      [`@media screen and (min-width: ${breakpoints.sm})`]: {
        paddingLeft: '5%',
        paddingRight: '5%',
      },
      [`@media screen and (min-width: ${breakpoints['2xl']})`]: {
        paddingLeft: '3rem',
        paddingRight: '3rem',
      },
    }),
  };
});

export default Container;
