import { Heading, Text, Flex } from '@chakra-ui/react';
import styled from '@emotion/styled';
import Tilt from 'react-tilt';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import { openInNewTab } from '../../helpers';

const Container = styled(Tilt)(({ thumbnailUrl }) => ({
  display: 'inline-block',
  background: `url(${thumbnailUrl})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  zIndex: 0,
  minHeight: 300,
  width: '100%',
  cursor: 'pointer',
  margin: '4px 0',
}));

const Card = ({
  loc, desc, thumbnailUrl, url,
}) => {
  const { colors } = useTheme();
  return (
    <Container
      options={{
        reverse: false,
        max: 8,
        perspective: 1000,
        scale: 1.05,
        speed: 300,
        transition: true,
        axis: null,
        reset: true,
        easing: 'cubic-bezier(.03,.98,.52,.99)',
      }}
      thumbnailUrl={thumbnailUrl}
    >
      <Flex
        h="100%"
        minH={300}
        w="100%"
        alignItems="center"
        justifyContent="center"
        flexDir="column"
        css={{
          opacity: 0,
          ':hover': {
            backgroundColor: `${colors.gray[900]}80`,
            backdropFilter: 'blur(1.5px) saturate(1.5)',
            opacity: 100,
          },
        }}
        onClick={() => openInNewTab(url)}
      >
        <Heading color="#FFF" fontWeight="semibold" size="lg" maxW="600">
          {loc}
        </Heading>
        <Text
          mt="2"
          fontWeight="semibold"
          fontSize="1rem"
          textColor="gray.300"
          maxW="600"
        >
          {desc}
        </Text>
      </Flex>
    </Container>
  );
};

Card.propTypes = {
  loc: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Card;
