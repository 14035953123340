import styled from '@emotion/styled';

const Badge = styled.div(({ theme: { radii } }) => ({
  borderRadius: radii.lg,
  display: 'inline-block',
  background: '#F9F9F90E',
  zIndex: 0,
  padding: '6px 12px',
  margin: '4px 0',
}));

export default Badge;
