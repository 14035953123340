/** @jsxImportSource @emotion/react */

import { Flex, HStack, Heading } from '@chakra-ui/react';
import Container from '../Container/Container';
import ProfileURLs from '../Header/components/ProfileURLs/ProfileURLs';
import SideMenu from '../Header/components/SideMenu/SideMenu';

const Footer = () => (
  <Container css={{ minHeight: '20vh' }}>
    <Flex direction="column" alignItems="center">
      <HStack spacing={[10, 10, 20]}>
        <SideMenu size="sm" />
      </HStack>
      <HStack spacing={5} mt="2rem">
        <ProfileURLs />
      </HStack>
      <Heading size="sm" fontWeight="semibold" color="gray.400" my="2rem" textAlign="center">
        Designed & Developed with
        <span style={{ margin: '0 6px' }}>💜</span>
        by Tejaswini L Vantigodi
      </Heading>
    </Flex>
  </Container>
);

export default Footer;
