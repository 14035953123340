/** @jsxImportSource @emotion/react */
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { useCallback, useState } from 'react';
import Hamburger from 'hamburger-react';
import { useEvent } from 'react-use';
import { useBreakpointValue } from '@chakra-ui/react';
import BaseContainer from '../Container/Container';
import { ReactComponent as HeaderLogo } from '../../assets/svg_js_logo.svg';
import MobileMenu from './components/MobileMenu/MobileMenu';
import SideMenu from './components/SideMenu/SideMenu';

const Container = styled(BaseContainer)(
  ({
    isScrolled, isScreenLargerThanMd, showSlider, theme: { colors },
  }) => {
    /** minmax of 4, 6 and 7 */
    const isBlur = isScrolled && (isScreenLargerThanMd || !showSlider);
    return {
      top: 0,
      width: '100vw',
      left: 0,
      zIndex: 30,
      transitionProperty: 'transform',
      transitionTimingFunction: 'ease-in-out',
      transitionDuration: '200ms',
      position: 'fixed',
      height: '4.5rem',
      backdropFilter: isBlur ? 'blur(4px)' : 'none',
      ...(isScrolled
        ? {
          borderBottom: `0.1px solid ${colors.gray[600]}30`,
        }
        : {
          transform: 'translateY(1rem)',
        }),
    };
  },
);

const HamburgerIconDiv = styled.div(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'block',
    marginLeft: 'auto',
    zIndex: 10,
    [`@media screen and (min-width: ${breakpoints.md})`]: {
      display: 'none',
    },
  };
});

const Logo = styled(HeaderLogo)(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'none',
    height: 18,
    [`@media screen and (min-width: ${breakpoints.md})`]: {
      display: 'block',
    },
  };
});

const SideFlex = styled.nav(({ theme }) => {
  const { breakpoints } = theme;
  return {
    display: 'none',
    marginLeft: 'auto',
    [`@media screen and (min-width: ${breakpoints.md})`]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& > a': {
      marginLeft: '30px',
    },
  };
});

const BaseHeader = styled.div(() => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
}));

const Header = () => {
  const [showSlider, setShowSlider] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const isScreenLargerThanMd = useBreakpointValue({ base: false, md: true });

  const onScroll = useCallback(() => {
    setScrolled(window.scrollY > 0);
  }, []);

  useEvent('scroll', onScroll);

  return (
    <Container
      as="header"
      showSlider={showSlider}
      isScrolled={isScrolled}
      isScreenLargerThanMd={isScreenLargerThanMd}
    >
      <BaseHeader>
        <Link to="/">
          <Logo />
        </Link>
        <SideFlex>
          <SideMenu showResume />
        </SideFlex>

        <HamburgerIconDiv>
          <Hamburger
            toggled={showSlider}
            toggle={setShowSlider}
            size={22}
            direction="right"
          />
        </HamburgerIconDiv>
        {showSlider && !isScreenLargerThanMd && (
          <MobileMenu onClose={() => setShowSlider(false)} isScrolled={isScrolled} />
        )}
      </BaseHeader>
    </Container>
  );
};

export default Header;
