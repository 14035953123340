/** @jsxImportSource @emotion/react */
import {
  keyframes, Heading, Text, Box, Img,
} from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useEffectOnce } from 'react-use';
import { Container, Header, Footer } from '../components';
import TejaUIImg from '../assets/png_tejaui_people.png';

const fadeInUp = keyframes`
    from {
        transform: translate3d(0,40px,0);
        opacity: 0;
    }
    to {
        transform: translate3d(0,0,0);
        opacity: 1;
    }
}
`;

const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
`;

const WorkHeading = styled(Heading)(() => ({
  marginTop: '4rem',
  overflowWrap: 'break-word',
  wordWrap: 'break-word',
  animation: `${fadeInUp} 700ms ease`,
}));

const WorkSub = styled(Text)(() => ({
  animation: `${fadeIn} 700ms ease-in-out`,
}));

const TejaUI = () => {
  useEffectOnce(() => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  }, []);
  return (
    <>
      <Header />
      <Container css={{ minHeight: '90vh' }} type="center">
        <Box mt="5rem"><br /></Box>
        <WorkHeading> Why UI???</WorkHeading>
        <WorkSub fontSize="lg" color="gray.600" mt="2rem" maxW="900">
          When we lose the magic of visualizing beauty through the mundanity of our lives,
          I discovered myself enthralled in the art of design making.
          I could only make reasons to symbolize things around me with my artistic skills.
          The world can only look random if you are incapable of looking at it
          without an artistic feel.
          I found myself living through the randomness around me and giving it a form that
          beholds the vision of the viewer.
          I kept learning, growing, and most importantly bringing my
          vision into life with a sense of responsibility and artistry.
          <br />
          <br />

          Computer languages are expressions that could bring life.
          I found this statement valid when I could express my vision
          using necessary computer tools.
          The randomness of most people&apos;s observations could be
          rationalized by me using my technical
          skills with an artistic touch through my interfaces and designs.
        </WorkSub>
        <WorkSub color="white" fontSize="2xl" my="2rem">
          ❝Did the soul work to identify my next stop and took it.❞
        </WorkSub>
        <WorkSub fontSize="lg" color="gray.600" maxW="900">
          I was introduced to UI/UX a few years ago during my undergrad,
          which got me thinking about how my eye for colors, typography can be put
          to good technical use. I was into the field, did more research and kick
          started my journey off into the world of UI. My knowledge of art, HTML, CSS, JS,
          React helps me build products which I can see through an artistic and a
          functional perspective.
          I enrolled myself in various summer trainings offered on coursera by
          California Institute of Arts, brushing up my skills to form a base for my higher studies.
          <br />
          <br />

          I am adamant, disciplined and dedicated to the field and aims to develop beautiful
          apps, will provide insights and incorporate feedback into product design.
        </WorkSub>
        <Img src={TejaUIImg} alt="An image displaying who matters" w="100%" my="5rem" maxW="900" />
      </Container>
      <Footer />
    </>
  );
};

export default TejaUI;
